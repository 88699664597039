export default {
  dateStart: {
    key: 'dateStart',
    type: 'text',
    title: '',
    value: '',
    placeholder: 'С даты',
    action: 'payments/updateDateStart',
    required: true,
    size: 40,
    mask: {
      regex: '\\d*',
      placeholder: ' ',
      minChars: 1,
    },
    error: {
      class: '',
      message: '',
    },
    validation: {
      empty: 'Поле не заполено',
    },
  },
  dateEnd: {
    key: 'dateEnd',
    type: 'text',
    title: '',
    value: '',
    placeholder: 'До даты',
    action: 'payments/updateDateEnd',
    required: true,
    size: 40,
    mask: {
      regex: '\\d*',
      placeholder: ' ',
      minChars: 1,
    },
    error: {
      class: '',
      message: '',
    },
    validation: {
      empty: 'Поле не заполено',
    },
  },
};
