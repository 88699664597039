
import { Component, Vue, Prop } from '@/lib/decorator';
import { ref } from 'vue';

import ModalBtnBottom from '@/layouts/ModalBtnBottom.vue';
import IconFont from '@/components/icons/IconFont.vue';
import { strings } from '@/lib/stringConst';
import FormInput from '@/components/form/Input/index.vue';
import FormTextarea from '@/components/form/Textarea/index.vue';
import FormButton from '@/components/form/Button/index.vue';
import formValidate from '@/lib/formFactory/validate';
import { clearMoneyFormat } from '@/lib/Utils';

import PayoutsModule, { PayoutItemInterface } from '@/store/finance/payouts/';
import { GuiFormCheckbox } from '@library/gigant_ui';

@Component({
  components: {
    GuiFormCheckbox,
    ModalBtnBottom,
    IconFont,
    FormInput,
    FormTextarea,
    FormButton,
  },
})
export default class EditPayout extends Vue {
  @Prop() data!: never;

  form = ref(null);
  validate = formValidate(this.form);

  debit = (this.data as { debit: string }).debit || '';
  comment = (this.data as { comment: string }).comment || '';
  voucher = (this.data as { voucher: boolean }).voucher || false;

  get btnSave(): string {
    return strings.btnSave;
  }

  closeModal(): void {
    PayoutsModule.closeModalEditPayout();
  }

  save(): void {
    const amount = this.debit ? clearMoneyFormat(this.debit) : 0;

    PayoutsModule.saveUpdate({
      id: (this.data as { id: string }).id,
      debit: amount,
      comment: this.comment,
      voucher: this.voucher,
    } as PayoutItemInterface);
  }
}
