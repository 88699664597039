
import { Component, Vue } from '@/lib/decorator';

import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import DatePicker from '@/components/form/DatePickerFilter.vue';
import IconFont from '@/components/icons/IconFont.vue';

import EditPayout from './_editPayout.vue';
import Export from './_export/index.vue';
import ModalBlock from '@/components/Modal.vue';
import AppHeaderMain from '@/layouts/partials/AppHeaderMain.vue';
import FormButton from '@/components/form/Button/index.vue';

import UserModule from '@/store/user';
import PayoutsModule, { PayoutItemInterface } from '@/store/finance/payouts/';
import FinanceModule from '@/store/finance';
import AppTable from '@/components/ui/table/Table.vue';
import TextMoney from '@/components/table-items/TextMoney.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import AppSimpleCheckbox from '@/components/ui/form/SimpleCheckbox.vue';
import SiteModule from '@/store/site';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { FilterSettings } from '@/lib/layouts/page/filter.interface';
import { PayoutsModalDateType, PayoutsModalParamsType } from '@/store/finance/payouts/modalParams';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    AppCol,
    AppRow,
    AppSimpleCheckbox,
    TextDatetime,
    TextMoney,
    AppTable,
    TabsNav,
    DatePicker,
    ModalBlock,
    EditPayout,
    Export,
    AppHeaderMain,
    FormButton,
    IconFont,
  },
})
export default class PayoutsMain extends Vue {
  colspan = {
    id: '1',
    span: 3,
  };

  selectedPayouts = [];
  modalValue = {};
  exportIsShow = false;

  get isSupervisor(): boolean {
    return UserModule.isSupervisor;
  }

  get tabsNav(): TabsNavInterface {
    return FinanceModule.tabsNav;
  }

  get currentTab(): string {
    return FinanceModule.allTabs.payouts?.id;
  }

  get settings(): PageInterface {
    return PayoutsModule.pageSettings;
  }

  get filterSettings(): FilterSettings {
    return PayoutsModule.filter.filterSettings;
  }

  get model(): string | boolean | Record<string, PayoutsModalDateType> {
    return PayoutsModule.modalParams.dateRange.model;
  }

  get modalParams(): PayoutsModalParamsType {
    return PayoutsModule.modalParams;
  }

  get userCanEditPartner(): boolean {
    return UserModule.userHasPermission('CAN_EDIT_PARTNER');
  }

  checkIsOwnPartner(partnerUuid: string): boolean {
    return UserModule.partner?.uuid === partnerUuid;
  }

  selectableFunction(item: { partnerUuid: string }): boolean {
    return this.checkIsOwnPartner(item.partnerUuid);
  }

  voucherCheck(checked: boolean, item: { voucher: boolean }): void {
    if (SiteModule.isBlock) {
      return;
    }

    item.voucher = checked;
    PayoutsModule.saveUpdate(item as PayoutItemInterface);
  }

  change(value: never[]): void {
    this.selectedPayouts = value;
  }

  openModal(item: Record<string, string | number>): void {
    this.modalValue = item;
    this.showModal();
  }

  selectDateEnd(date: { day: string; month: string; year: string }): void {
    PayoutsModule.updateDateEnd(`${date.day}/${date.month}/${date.year}`);
  }

  selectDateStart(date: { day: string; month: string; year: string }): void {
    PayoutsModule.updateDateStart(`${date.day}/${date.month}/${date.year}`);
  }

  selectAmount(value: string): void {
    PayoutsModule.updatePageAmountItems(value);
  }

  clearSort(): void {
    PayoutsModule.filter.resetFilter();
  }

  async resetFilters(): Promise<void> {
    await PayoutsModule.setPageToBegin();
    await PayoutsModule.filter.resetFilter();
    await PayoutsModule.getList();
  }

  async updateList(): Promise<void> {
    await PayoutsModule.setPageToBegin();
    await PayoutsModule.filter.updateFilter();
    await PayoutsModule.getList();
  }

  showModal(): void {
    PayoutsModule.showModalEditPayout();
  }

  closeModal(): void {
    this.modalValue = {};
    PayoutsModule.closeModalEditPayout();
  }

  openModalDeleteAll(): void {
    PayoutsModule.SET_SELECTED(this.selectedPayouts);
    PayoutsModule.showModalDelete();
  }

  mounted(): void {
    FinanceModule.initTabsItems();
    PayoutsModule.init();
  }
}
