
import { Component, Vue } from '@/lib/decorator';
import { ref } from 'vue';
import moment from 'moment';

import ModalBtnBottom from '@/layouts/ModalBtnBottom.vue';
import IconFont from '@/components/icons/IconFont.vue';
import FormMultiSelect from '@/components/form/MultiSelect/index.vue';
import DatePicker from '@/components/form/DatePickerFilter.vue';

import { strings } from '@/lib/stringConst';
import formValidate from '@/lib/formFactory/validate';

import datePicker from './date-picker';
import { exports } from '@/api/payouts';

import ResponseHandlerModule from '@/store/modules/responseHandler';
import FormButton from '@/components/form/Button/index.vue';
import { GuiFormCheckbox } from '@library/gigant_ui';

@Component({
  emits: ['hide'],
  components: {
    GuiFormCheckbox,
    FormButton,
    ModalBtnBottom,
    IconFont,
    FormMultiSelect,
    DatePicker,
  },
})
export default class EditPayout extends Vue {
  form = ref(null);
  validate = formValidate(this.form);

  allTime = true;

  dateStart = datePicker.dateStart;
  dateEnd = datePicker.dateEnd;

  get btnExport(): string {
    return strings.export;
  }

  get filter(): Record<string, string | number> {
    return {
      periodStart: this.dateStart.value && !this.allTime ? 'periodStart' : '',
      periodEnd: this.dateEnd.value && !this.allTime ? 'periodEnd' : '',
    };
  }

  closeModal(): void {
    this.$emit('hide');
  }

  updateAllTime(value: number): void {
    if (value) {
      this.dateStart.value = '';
      this.dateEnd.value = '';
    }
  }

  async updateDateStart(date: { day: string; month: string; year: string } | string): Promise<void> {
    if (!date) {
      return;
    }

    const value = typeof date != 'string' ? `${date.day}/${date.month}/${date.year}` : date;

    this.dateStart.value = value;

    const dataEnd = this.dateEnd.value.split('.');
    const dataEndTimestamp = new Date(dataEnd[1] + '/' + dataEnd[0] + '/' + dataEnd[2]).getTime() / 1000;

    const dateStart = this.dateStart.value.split('.');
    const dateStartTimestamp = new Date(dateStart[1] + '/' + dateStart[0] + '/' + dateStart[2]).getTime() / 1000;

    if (dateStartTimestamp > dataEndTimestamp) {
      this.dateEnd.value = value;
    }
  }

  async updateDateEnd(date: { day: string; month: string; year: string } | string): Promise<void> {
    if (!date) {
      return;
    }

    const value = typeof date != 'string' ? `${date.day}/${date.month}/${date.year}` : date;

    this.dateEnd.value = value;

    const dataEnd = this.dateEnd.value.split('.');
    const dataEndTimestamp = new Date(dataEnd[1] + '/' + dataEnd[0] + '/' + dataEnd[2]).getTime() / 1000;

    const dateStart = this.dateStart.value.split('.');
    const dateStartTimestamp = new Date(dateStart[1] + '/' + dateStart[0] + '/' + dateStart[2]).getTime() / 1000;

    if (dateStartTimestamp >= dataEndTimestamp) {
      this.dateStart.value = value;
    }
  }

  prepareDate(date: string): string {
    const result = moment(date, 'DD/MM/YY').format('DD/MM/YYYY').split('/');

    return `${result[2]}-${result[1]}-${result[0]}`;
  }

  buildFilter(): string {
    const names = Object.values(this.filter).filter((item: string | number) => {
      return item;
    });

    const result = names.map((name: string | number, index: number) => {
      if (name === 'periodStart') {
        return `filters[${index}][id]=${name}&filters[${index}][value]=${this.prepareDate(this.dateStart.value)}`;
      }

      if (name === 'periodEnd') {
        return `filters[${index}][id]=${name}&filters[${index}][value]=${this.prepareDate(this.dateEnd.value)}`;
      }
    });

    if (!result.length) {
      return '';
    }

    return `?${result.join('&')}`;
  }

  async save(): Promise<void> {
    try {
      await exports(this.buildFilter());

      ResponseHandlerModule.showNotify({ message: 'Выгрузка создана', type: 'ok' });

      this.closeModal();
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  async mounted(): Promise<void> {
    this.dateStart.value = '';
    this.dateEnd.value = '';
  }
}
